import { type Locale } from 'bb/config/marketConfigUtils';
import {
    type Badge as BadgeType,
    type BookLightResponse
} from 'bb/discovery/types';
import { type TFunction } from 'bb/i18n';
import { useTranslationsFromDiscovery } from 'bb/ui/hooks/useTranslationsFromDiscovery';

const useAllyCardTitle = (
    book: BookLightResponse,
    t: TFunction<'bookCard' | 'book'>,
    locale: Locale,
    bookTopListNumber?: number,
    primaryBadge?: BadgeType,
    secondaryBadge?: BadgeType,
    ownedAudiobook?: boolean,
    ownedEbook?: boolean
) => {
    const parts: string[] = [];

    const primaryBadgeText =
        useTranslationsFromDiscovery(primaryBadge?.translationKey ?? '') ?? '';
    const secondaryBadgeText =
        useTranslationsFromDiscovery(secondaryBadge?.translationKey ?? '') ??
        '';

    const addTopListNumber = () => {
        if (bookTopListNumber) {
            parts.push(
                t('bookCard:accessibility_badgeNumber', {
                    badgeNumber: bookTopListNumber
                }) + ','
            );
        }
    };

    const addBookIntroduction = () => {
        parts.push(
            t('bookCard:accessibility_bookIntroduction', {
                bookTitle: book.title,
                bookAuthor: book.author
            })
        );
    };

    const addBadgeInfo = () => {
        if (bookTopListNumber && secondaryBadge) {
            parts.push(t('book:accessibility_badge'));
        } else if (primaryBadge && !bookTopListNumber) {
            parts.push(t('book:accessibility_badge'));
        } else if (!bookTopListNumber && (primaryBadge || secondaryBadge)) {
            parts.push(t('book:accessibility_badge'));
        }

        if (primaryBadge && !bookTopListNumber) {
            if (primaryBadge.icon === 'top10') {
                parts.push(t('bookCard:accessibility_top10Badge'));
            } else if (primaryBadge.icon === 'top100') {
                parts.push(t('bookCard:accessibility_top100Badge'));
            }
            parts.push(primaryBadgeText);
        }

        if (secondaryBadge) {
            parts.push(secondaryBadgeText);
        }
    };

    const addAdditionalInfo = () => {
        if (book.series) {
            parts.push(`${t('book:part')} ${book.series.partnumber},`);
            parts.push(`${t('book:series')} ${book.series.name},`);
        }

        if (book.grade) {
            parts.push(
                t('bookCard:accessibility_rating', {
                    rating: Intl.NumberFormat(locale).format(book.grade)
                })
            );
        }

        /**
         * if the audio book is not on sale only add the accesibility text for the audio book.
         * Else if the audio book is on sale and the user does not own the audio book,
         * add the accessibility text for the audio book and the on sale text.
         */
        if (book.audio || book.audiobookisbn) {
            if (!book.audiobooksinglesale && !ownedAudiobook) {
                parts.push(`${t('bookCard:accessibility_audiobook')}.`);
            } else if (book.audiobooksinglesale && !ownedAudiobook) {
                parts.push(
                    `${t('bookCard:accessibility_audiobook')}${t('bookCard:accessibility_onSale')}`
                );
            }
        }

        /**
         * if the ebook is not on sale only add the accesibility text for the ebook.
         * else if the ebook is on sale and the user does not own the ebook,
         * add the accessibility text for the ebook and the on sale text.
         * */
        if (book.ebook || book.ebookisbn) {
            if (!book.ebooksinglesale && !ownedEbook) {
                parts.push(`${t('bookCard:accessibility_ebook')}.`);
            } else if (book.ebooksinglesale && !ownedEbook) {
                parts.push(
                    `${t('bookCard:accessibility_ebook')}${t('bookCard:accessibility_onSale')}`
                );
            }
        }
    };

    addTopListNumber();
    addBookIntroduction();
    addBadgeInfo();
    addAdditionalInfo();

    return parts.join(' ');
};

export default useAllyCardTitle;
